.Root {
  text-align: left;

  padding: 10px 5px 10px 18px;
  color: #444;
  cursor: pointer;

  text-decoration: none;
}

.Root:hover {
  color: #000;
  background: #f4f4f5;
}

.Icon {
  width: 20px;
}
.Icon::before {
  font-size: 14px;
}
.Label {
  font-size: 13px;
  font-weight: 500;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

  padding-left: 2px;
}
