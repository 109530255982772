.center {
  text-align: center;
}

.capa {
  height: 460px;
  width: 100%;
  background-position: center;
  background-image-resize: 4;
}
.Noites {
  font-family: "noir-regular";
  font-size: 18px;
  color: #00205c;

  line-height: 27px;
  height: 27px;
  margin-top: 40px;
  text-align: center;
}
.Title {
  font-family: "noir-semibold";
  font-size: 42px;
  color: #00205c;

  text-align: center;
}
.Hotel {
  font-family: "noir-regular";
  font-size: 18px;
  color: #00205c;
  text-align: center;
}

.Price {
  font-family: "noir-medium";
  background: #eeb700;
  border-radius: 35px;
  padding: 5px 25px;
  text-align: center;
  display: inline-block;

  margin: 15px auto 0 auto;
}
.Price .Desde {
  font-family: "noir-medium";
  line-height: 10px;
  font-size: 12px;
  color: white;
}
.Price .Preco {
  font-size: 48px;
  line-height: 48px;
  color: white;
}

.Descricao {
  font-family: "noir-light";
  font-size: 15px;
  color: #00205c;
  text-align: center;
  margin-top: 30px;
}
.Incluido {
  font-size: 11px;
  color: #00205c;

  margin-bottom: 10px;
}

#Footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
