.Address {
  font-size: 15px;
  line-height: 15px;
  font-family: "Helvetica";
  color: #979797;
  white-space: pre-line;

  margin-bottom: 10px;
}

.Image {
  height: 62px;
}
