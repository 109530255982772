.Facebook {
  --width: 1080px;
  --height: 1080px;
  --scale: 0.55;
}
.Instagram {
  --width: 1080px;
  --height: 1980px;
  --scale: 0.3;
}

.FrameWrapper {
  width: calc(var(--width) * var(--scale));
  height: calc(var(--height) * var(--scale));
  overflow: hidden;

  transition: all 0.4s;
}

.Frame {
  transition: all 0.4s;
  transform-origin: 0 0;
  transform: scale(var(--scale));
}

.Frame,
.Frame .CoverImage {
  width: var(--width);
  height: var(--height);

  object-fit: cover;
}

/* 
.Frame.Instagram {
    height: calc(1920px * 0.28125);
    width: calc(1080px * 0.28125);
} */
/* .Frame.Facebook .Root {
    width: 1080px;
    height: 1080px;
    transform: scale(0.5);
} */
/* .Frame.Instagram .Root {
    width: 1080px;
    height: 1920px;
    transform: scale(0.28125);
} */
.Root {
  position: relative;
  transform-origin: 0 0;

  transition: all 0.5s;
  margin: 0 auto;
}
.Image {
  height: 100%;
  object-fit: cover;
}

.Title {
  font-size: 104px;
  line-height: 94px;
  font-family: "noir-semibold";

  padding: 15px 25px;
  display: inline-block;
  text-align: center;

  transition: all 0.5s;
}
.Subtitle {
  font-size: 31px;
  font-family: "noir-regular";

  display: inline-block;
  text-align: center;
  padding: 5px 20px;
  transition: all 0.5s;
}
.Subtitle b {
  font-family: "noir-semibold";
}

.Top,
.Center {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.5s;
}
.Top {
  top: 16%;
}
.Center {
  top: 35%;
}

/* title */
.Transparent .Title {
  color: white;
}
.Blue .Title {
  color: white;
  background: #042054;
}
.Yellow .Title {
  background: #ffc10c;
  color: #042054;
}

/* sub title */
.SubOpaque .Subtitle {
  color: #042054;
  background-color: white;
}
.SubFullTransparent .Subtitle {
  color: white;
}
.SubTransparent .Subtitle {
  background: rgba(255, 255, 255, 0.6);
  color: #042054;
}

.Logo {
  width: 300px;

  position: absolute;
  bottom: 5%;
  text-align: center;

  left: calc(50% - 150px);
}
