.Root {
  padding: 15px;

  min-width: 710px;
}

.Title {
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 15px;
}
