.AgencyLinks {
  font-size: 7px;
  color: #00205c;
  height: 10px;
}

.Address {
  font-size: 8px;
}

.PhoneCallMsg {
  font-size: 8px;
  color: gray;

  margin-top: 15px;
}
