@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "noir-regular";
  src: local("noir"), url(./assets/fonts/noir/NoirPro-Regular.woff2)
    format("woff2"), url(./assets/fonts/noir/NoirPro-Regular.eot)
    format("opentype");
}
@font-face {
  font-family: "noir-semibold";
  src: local("noir"), url(./assets/fonts/noir/NoirPro-SemiBold.woff2)
    format("woff2"), url(./assets/fonts/noir/NoirPro-SemiBold.eot)
    format("opentype");
}
@font-face {
  font-family: "noir-medium";
  src: local("noir"), url(./assets/fonts/noir/NoirPro-Medium.woff2)
    format("woff2"), url(./assets/fonts/noir/NoirPro-Medium.eot)
    format("opentype");
}
@font-face {
  font-family: "noir-light";
  src: local("noir"), url(./assets/fonts/noir/NoirPro-Light.woff2)
    format("woff2"), url(./assets/fonts/noir/NoirPro-Light.eot)
    format("opentype");
}
