.Root {
  display: grid;
  grid-template-areas:
    "main main"
    "menu content";
  grid-template-columns: 230px auto;
  grid-template-rows: 50px auto;
  height: 100vh;
}

.Main {
  grid-area: main;

  background: var(--primary-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Menu {
  grid-area: menu;

  background: #f9fafc;
}

.Content {
  grid-area: content;

  background: #ecf0f5;
  border-left: 1px solid #d2d6de;

  text-align: left;
}

.Logo {
  width: 200px;
  margin: 5px 15px 0 15px;
}

.User {
  color: white;
  margin-right: 15px;
  font-size: 14px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.User i {
  color: white;
}
