.center {
  text-align: center;
}

.capa {
  height: 460px;
  width: 100%;
  background-position: center;
  background-image-resize: 4;
}
.Noites {
  font-family: "Helvetica";
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
  color: #575756;

  height: 27px;
  text-align: left;
}
.Title {
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 50px;
  color: white;

  text-align: center;
  padding: 35px 0;
}
.Hotel {
  font-family: "Helvetica";
  font-size: 36px;
  font-weight: bold;
  color: #575756;
  text-align: center;

  padding: 45px 0;
  margin-bottom: 10px;
}

.Desde {
  margin-top: 2px;
  font-size: 16px;
  color: #575756;
}
.Moeda {
  font-size: 46px;
  margin-top: 6px;
}
.Price {
  font-size: 112px;
  line-height: 85px;
  font-weight: bold;
}

.Description {
  font-family: "Helvetica";
  font-size: 20px;
  line-height: 24px;
  color: #575756;
  margin-top: 28px;
  white-space: pre-line;
}
.Incluido {
  font-size: 12px;
  line-height: 14px;
  color: #979797;

  margin-bottom: 10px;
}

#Footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
