.Facebook {
  --width: 1080px;
  --height: 1080px;
  --scale: 0.55;
}
.Instagram {
  --width: 1080px;
  --height: 1980px;
  --scale: 0.3;
}

.LeftLogo,
.RightLogo {
  width: 225px;
  position: absolute;
  top: 200px;
  text-align: center;
  background: white;
  transition: all 0.5s;

  padding: 30px 50px 30px 30px;
}
.LeftLogo {
  left: 0px;
}
.RightLogo {
  right: 0px;
}

.Facebook .Logo {
  top: 0px;
  right: 40px;

  padding: 50px 30px 30px 30px;
}

.LeftContent,
.RightContent {
  position: absolute;
  bottom: 0px;
  width: 440px;
}
.LeftContent {
  left: 90px;
}
.RightContent {
  right: 90px;
}

.Facebook .Content {
  left: 120px;
}

.Title {
  color: white;
  border-radius: 55px 55px 0px 0px;
  padding: 45px 35px 25px 35px;

  font-size: 64px;
  font-weight: bold;
  line-height: 64px;

  font-family: "Helvetica";
  text-align: left;
  transition: all 0.5s;
  text-align: left;
  width: 100%;
}
.Body {
  background: white;
  padding: 25px 40px;
}
.Subtitle {
  font-size: 31px;
  font-family: "Helvetica";
  width: 100%;

  transition: all 0.5s;
}
.AccommodationName {
  color: #575756;
  font-size: 30px;
}
.Nights {
  color: #575756;
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
}
.RNAVT {
  color: #575756;
  position: absolute;
  right: 25px;
  bottom: 25px;
  font-size: 12px;
}

.FrameWrapper {
  width: calc(var(--width) * var(--scale));
  height: calc(var(--height) * var(--scale));
  overflow: hidden;

  transition: all 0.4s;
}

.Frame {
  transition: all 0.4s;
  transform-origin: 0 0;
  transform: scale(var(--scale));
}

.Frame,
.Frame .CoverImage {
  width: var(--width);
  height: var(--height);

  object-fit: cover;
}

/* 
.Frame.Instagram {
    height: calc(1920px * 0.28125);
    width: calc(1080px * 0.28125);
} */
/* .Frame.Facebook .Root {
    width: 1080px;
    height: 1080px;
    transform: scale(0.5);
} */
/* .Frame.Instagram .Root {
    width: 1080px;
    height: 1920px;
    transform: scale(0.28125);
} */
.Root {
  position: relative;
  transform-origin: 0 0;

  transition: all 0.5s;
  margin: 0 auto;
}
.Image {
  height: 100%;
  object-fit: cover;
}

.Top,
.Center {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.5s;
}
.Top {
  top: 16%;
}
.Center {
  top: 35%;
}

/* title */
.Transparent .Title {
  color: white;
}
.Blue .Title {
  color: white;
  background: #042054;
}
.Yellow .Title {
  background: #ffc10c;
  color: #042054;
}

/* sub title */
.SubOpaque .Subtitle {
  color: #042054;
  background-color: white;
}
.SubFullTransparent .Subtitle {
  color: white;
}
.SubTransparent .Subtitle {
  background: rgba(255, 255, 255, 0.6);
  color: #042054;
}
